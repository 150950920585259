import { Container, Row, Col } from "react-bootstrap";
import rayIcon from "../assets/icons/i-ray.svg"

export const Resultados = () => {

  return (
    <section className="resultados" id="resultados">
      <Container>
        <Row className="d-flex justify-content-center">
          <Row xs={12} className="d-flex justify-content-center">
            <p className="resultados-header d-flex justify-content-center mb-0">Resultados</p>
            <img className="d-flex justify-content-center mt-0" src={rayIcon} style={{ "width": "50px", "height": "58.33px" }} alt="Ray Img"/>
          </Row>
          <Row className="py-4 gap-2 gap-md-0">
            <Col sm={12} md={4}>
              <Row className="resultados-text d-flex justify-content-center">+200</Row>
              <Row className="resultados-subtext d-flex justify-content-center text-center">Faturas de energia<br /> analisadas</Row>
            </Col>
            <Col sm={12} md={4}>
              <Row className="resultados-text d-flex justify-content-center">34GWh</Row>
              <Row className="resultados-subtext d-flex justify-content-center">Economia global obtida em<br /> faturas analisadas</Row>
            </Col>
            <Col sm={12} md={4}>
              <Row className="resultados-text d-flex justify-content-center">44k</Row>
              <Row className="resultados-subtext d-flex justify-content-center">Toneladas de co2<br /> economizado</Row>
            </Col>
          </Row>
          <Row className="resultados-info d-flex flex-inline justify-content-center pt-4">Economia real, sem complicações. Permita-nos otimizar sua conta<br /> de energia enquanto você impulsiona o sucesso do seu negócio.</Row>
        </Row>
      </Container>
    </section>
  )
}
