import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import complexo from '../assets/icons/i-complexo.svg'
import falta from '../assets/icons/i-falta-pessoal.svg'
import demorado from '../assets/icons/i-demorado.svg'
import logoRaio from '../assets/img/logo-raio.svg'


export const ContasDeEnergias = () => {
  return (
    <section className="contasDeEnergias" id="contas">
      <Container>
        <Row className="aligh-items-center text-center" style={{ justifyContent: "center" }}>
          <Col xs={12} md={6} xl={7}>
            <h1><b>CONTAS DE ENERGIA</b></h1>
            <h1>ELEVADAS SÃO UM <b>DESAFIO</b></h1>
            <h1>PARA EMPRESAS.</h1>
            <img src={logoRaio} alt="logoRaio" style={{ width: 32, height: 32, marginTop: 10, marginBottom: 10 }}></img>
            <p><b>Indentificar oportunidades de economia pode ser:</b></p>
          </Col>
        </Row>
        <Row className="rowContasIcons">
          <Col xs={12} md={4} style={{display: "flex", justifyContent: "flex-start"}}>
            <img src={complexo} alt="complexo" style={{ width: 32, height: 32 }}></img>
            <p>Complexo</p>
          </Col>
          <Col xs={12} md={4} style={{display: "flex", justifyContent: "flex-start"}}>
            <img src={demorado} alt="complexo" style={{ width: 32, height: 32 }}></img>
            <p>Demorado</p>
          </Col>
          <Col xs={12} md={4} style={{display: "flex", justifyContent: "flex-start"}}>
            <img src={falta} alt="complexo" style={{ width: 32, height: 32 }}></img>
            <p>Falta de Pessoal Capacitado</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}