import { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';
const baseURL = "https://energiabotwhats-5jycpcvpcq-uc.a.run.app/leads";
const authURL = "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCaAPS3dZxp7zTQ4Sv9Nm0oPfdxCWMlPL4";

export const Formulario = () => {

  const { Formik } = formik;

  const leadSchema =  yup.object().shape({
    nome: yup.string().required(),
    whatsapp: yup.string().required(),
    cnpj: yup.string().required(),
  });

  const formInitialDetails = {
    nome: '',
    whatsapp: '',
    cnpj: '',
  }

  const formAuth = {
    email: "danillobarros@gmail.com",
    password: "energia",
    returnSecureToken: true
  }

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('RECEBER RELATÓRIO');
  const [status, setStatus] = useState({});
  const [validated, setValidated] = useState(false);

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setButtonText("Enviando...");

    let responseAuth = await fetch(authURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formAuth),
    });

    let resultAuth = await responseAuth.json();

    let response = await fetch(baseURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${resultAuth.idToken}`,
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText(buttonText);
    let result = await response.json();
    setFormDetails(formInitialDetails);
    console.log("result :" + result.code);

    if (result.code === 200) {
      setStatus({ succes: true, message: 'Mensagem enviada com sucesso' });
    } else {
      setStatus({ succes: false, message: 'Something went wrong, please try again later.' });
    }
  };


  return (
    <Formik
      validationSchema={leadSchema}
      onSubmit={async (formDetails, { setSubmitting }) => {
       
        setSubmitting(false);

        setButtonText("Enviando...");

        let responseAuth = await fetch(authURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(formAuth),
        });

        let resultAuth = await responseAuth.json();

        let response = await fetch(baseURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${resultAuth.idToken}`,
          },
          body: JSON.stringify(formDetails),
        });
        setButtonText(buttonText);
        let result = await response.json();
        setFormDetails(formInitialDetails);
        console.log("result :" + result.code);

        if (result.code === 200) {
          setStatus({ succes: true, message: 'Mensagem enviada com sucesso' });
        } else {
          setStatus({ succes: false, message: 'Something went wrong, please try again later.' });
        }
      }}
      initialValues={formInitialDetails}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
    <section className="formulario" id="formulario">
      <Container>
        {/* Usar no-gutters class na linha para remover espaços ao redor da div*/}
        <Row className="d-flex justify-content-center gap-4">
          <Col className="d-flex flex-column justify-content-center justify-content-md-start gap-2" sm={12} md={"auto"}>
            <Row>
              <p className="d-flex text-center justify-content-center justify-content-md-start align-items-start" style={{ "color": "#1C4670", "font-family": "Saira", "font-style": "normal", "font-weight": "800", "font-size": "40px", "line-height": "100%", "text-transform": "uppercase" }}>
                Texto Formulário
              </p>
            </Row>
            <Row>
              <p className="d-flex justify-content-center justify-content-md-start align-items-start" style={{ "font-family": 'Red Hat Display', "font-style": "normal", "font-weight": "500", "font-size": "24px", "line-height": "100%", "color": "#172F4D" }}>
                Subtítulo
              </p>
            </Row>
          </Col>
          <Col className="d-flex justify-content-center" sm={12} md={"auto"}>

            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3 formulario-label" controlId="formNome">
                <Form.Label>Insira seu nome:</Form.Label>
                <Form.Control 
                   type="text" 
                   name="nome"
                   value={values.nome} 
                   placeholder="Nome" 
                   onChange={handleChange}
                   isValid={touched.nome && !errors.nome}
                   isInvalid={!!errors.nome}
                />
              </Form.Group>

              <Form.Group className="mb-3 formulario-label" controlId="formContato">
                <Form.Label>Insira seu contato (WhatsApp):</Form.Label>
                <Form.Control type="text" 
                name="whatsapp"
                value={values.whatsapp} 
                onChange={handleChange}
                isValid={touched.whatsapp && !errors.whatsapp}
                isInvalid={!!errors.whatsapp}
                placeholder="(99) 9 9999-9999" />
              </Form.Group>

              <Form.Group className="mb-3 formulario-label" controlId="formCnpj">
                <Form.Label>Insira o CNPJ da sua empresa:</Form.Label>
                <Form.Control 
                type="text" 
                name="cnpj"
                value={values.cnpj} 
                onChange={handleChange}
                isValid={touched.cnpj && !errors.cnpj}
                isInvalid={!!errors.cnpj}
                placeholder="99.999.999/9999-99" />
              </Form.Group>

              <div className="d-grid gap-2 my-4">
                <button className="formulario-submit" type="submit">{buttonText}</button>
              </div>

              <p className="formulario-text">
                Ao prosseguir você está de acordo com os <a href="/">Termos e Condições de Uso</a> do EnergiaCE.
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
    )}
    </Formik>
  )
}
