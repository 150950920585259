import { Container, Row, Col } from "react-bootstrap";
import logoRaio from '../assets/img/logo-raio.svg'
import logoInd from '../assets/img/i_industry.png'
import logoVar from '../assets/img/i-varejo.png'
import logoEmp from '../assets/img/i-empreendimentos.png'


export const Skills = () => {

  return (
    <section className="skill" id="pilares">
      <Container style={{paddingTop: 50}}>
        <Row className="aligh-items-center text-center" style={{ justifyContent: "center" }}>
          <Col>
              <p style={{color: "#ff9d0a", textAlign: "center", fontSize: 40, lineHeight: "120%",marginBottom: 16}}><b>EFICIÊNCIA ENERGÉTICA </b><br></br> <span style={{fontWeight: 300}}>PARA</span> <b>EMPRESAS</b></p>
              <img src={logoRaio} alt="logoRaio" style={{ width: 24, height: 28, marginBottom: 16 }}></img>
              <p style={{textAlign: "center", fontFamily: "RedHatDisplay-Medium", fontWeight: 500}}>Podemos atender vários nichos de empresas</p>
          </Col>
        </Row>
        <Row className="aligh-items-center text-center mt-5">
            <Col xs={12} md={4}>
              <img src={logoInd} alt="logoRaio" style={{ width: 160, height: 161, marginBottom: 16}}></img>
              <p style={{marginBottom: 16, textAlign: "center", justifyContent: "center" }}><b>INDÚSTRIAS</b></p>
              <p>Reduza os custos com energia e expanda sua produção com ações de baixo investimento</p>
            </Col>
            <Col xs={12} md={4}>
              <img src={logoVar} alt="logoRaio" style={{ width: 160, height: 161, marginBottom: 16 }}></img>
              <p style={{marginBottom: 16, textAlign: "center", justifyContent: "center"}}><b>VAREJO</b></p>
              <p>Soluções visando o conforto do cliente e economia para lojistas.</p>
            </Col>
            <Col xs={12} md={4}>
              <img src={logoEmp} alt="logoRaio" style={{ width: 160, height: 161, marginBottom: 16  }}></img>
              <p style={{marginBottom: 16,textAlign: "center", justifyContent: "center"}}><b>EMPREENDIMENTOS</b></p>
              <p>Ações de automação e retrofit com foco na redução dos custos com energia elétrica</p>
            </Col>
        </Row>
      </Container>
    </section>
  )
}
