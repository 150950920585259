import { Container, Row, Col, Link } from "react-bootstrap";
import logoRaio from '../assets/img/logo-raio.svg'
import logoCard1 from '../assets/img/img-beneficios-card1.png'
import logoCard2 from '../assets/img/img-beneficios-card2.png'
import logoCard3 from '../assets/img/img-beneficios-card3.png'


export const BeneficiosPrincipais = () => {

  return (
    <section className="benefits" id="beneficios">
      <Container style={{paddingTop: 50}}>
        <Row className="aligh-items-center text-center" style={{ justifyContent: "center" }}>
          <Col>
              <p>BENEFÍCIOS PRINCIPAIS:</p>
              <img className="benefits-logo-raio" src={logoRaio} alt="logoRaio"></img>
          </Col>
        </Row>
        <Row className="aligh-items-center text-center mt-5">
            <Col xs={12} md={4}>
              <img className="cards" src={logoCard1} alt="logoRaio" style={{ width: 160, height: 161, marginBottom: 16}}></img>
              <p style={{marginBottom: 16, textAlign: "center", justifyContent: "center" }}><b>ECONOMIA IMEDIATA NA FATURA</b></p>
              <p>Descubra <b>potenciais de redução de custos</b> e veja os resultados refletidos em sua próxima fatura.</p>
            </Col>
            <Col xs={12} md={4}>
              <img className="cards" src={logoCard2} alt="logoRaio" style={{ width: 160, height: 161, marginBottom: 16 }}></img>
              <p style={{marginBottom: 16, textAlign: "center", justifyContent: "center"}}><b>FOCO NOS DADOS ANALISADOS</b></p>
              <p><b>Deixe a parte técnica conosco</b> para que você possa se concentrar no desenvolvimento do seu negócio.</p>
            </Col>
            <Col xs={12} md={4}>
              <img className="cards" src={logoCard3} alt="logoRaio" style={{ width: 160, height: 161, marginBottom: 16  }}></img>
              <p style={{marginBottom: 16,textAlign: "center", justifyContent: "center"}}><b>PAGAMENTO A PARTIR DE PARTE DA ECONOMIA</b></p>
              <p>O cliente EnergiaCE tem o seu projeto pago mensalmente com <b>parte da economia gerada</b></p>
            </Col>
        </Row>
      </Container>
    </section>
  )
}
