import { Container, Row, Col } from "react-bootstrap";
import logoRaio from '../assets/img/logo-raio.svg'


export const PreCadastro = () => {

  return (
    <section className="pre-cadastro">
      <Container >
        <Row className="aligh-items-center text-center" 
        style={{ marginTop: 72, marginBottom: 72 ,justifyContent: "center", backgroundColor: "#d6f1ff", borderRadius: 16 ,padding: 32 }}>
          <Col>
              <h1><b>COMO FUNCIONA</b></h1>
              <img className="pre-cadastro-logo-raio" src={logoRaio} alt="logoRaio"></img>
              <p>
                Realize o pré-cadastro em nosso site, 
                nosso Chatbot entrará em contato para obter as informações necessárias. 
                Nossos algoritmos e equipe de engenheiros realizarão uma análise detalhada de
                 suas faturas, identificando padrões e áreas de otimização. Ações específicas 
                 são propostas para maximizar seus resultados.
              </p>
          </Col>
        </Row>        
      </Container>
    </section>
  )
}
