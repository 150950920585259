import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/Logo.png";
import linkedin from "../assets/icons/i-linkedin.svg"
import insta from "../assets/icons/i-insta.svg"


export const Rodape = () => {

  return (
    <section className="rodape" id="rodape">
      <Container>
        {/* Usar no-gutters class na linha para remover espaços ao redor da div*/}
        <Row className="rodape-start align-items-xs-start py-4">
          <Col className="rodape-contact rodape-centralize">
            <Row>
              <img src={headerImg} style={{ "width": "236px", "height": "63px" }} alt="Header Img"/>
            </Row>
            <Row>
              <p className="rodape-text my-0 py-0">(85) 98865-1000</p>
            </Row>
            <Row>
              <p className="rodape-email my-0 py-0">contato@energiace.com.br</p>
            </Row>
          </Col>
          <Col className="rodape-centralize d-flex flex-md-row-reverse">
            <button className="botao-enviar" type="submit">FALE CONOSCO {`>`}</button>
          </Col>
        </Row>
        {/* TODO: Ajustar width divider quando tela sm */}
        <Row className="divider"></Row>
        <Row className="d-flex align-items-start justify-content-between pt-4" style={{ color: "black" }}>
          <Col className="rodape-centralize d-flex gap-2">
            <a href="https://www.instagram.com/energiace" target="_blank"><img src={insta} alt="insta" style={{ width: 40, height: 40 }}></img></a>
            <a href="https://www.linkedin.com/company/energiace" target="_blank"><img src={linkedin} alt="linkedin" style={{ width: 40, height: 40 }}></img></a>
          </Col>
          <Col className="rodape-email rodape-centralize d-flex flex-md-row-reverse pt-md-0 pt-4" sm={6}>
            <p>©2024 Energia CE. Todos os direitos reservados</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
